import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentModule } from "./components/component.module";
import { LandingPageDirective } from "./components/landing-page/directive/landing-page.directive";
import { LandingPageComponent } from "./components/landing-page/landing-page.component";
import { MicrophoneService } from "./services/microphone.service";
import { LoadingLandingComponent } from "./components/loading-landing/loading-landing.component";

export function initializeApp(microphone: MicrophoneService) {
    return async (): Promise<any> => {
        await microphone.loadScript('https://cdn.jsdelivr.net/npm/onnxruntime-web/dist/ort.js');
        await microphone.loadScript('https://cdn.jsdelivr.net/npm/@ricky0123/vad-web@0.0.7/dist/bundle.min.js');
    }
}

@NgModule({
    declarations: [
        AppComponent,
        LandingPageComponent,
        LandingPageDirective,
        LoadingLandingComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        ComponentModule,
        FormsModule,
        HttpClientModule,
    ],
    providers: [
        MicrophoneService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [MicrophoneService],
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule {
}
