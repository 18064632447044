import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';

import { LandingPageComponent } from "./components/landing-page/landing-page.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";

const routes: Routes = [
    { path: 'project/:project_id', component: LandingPageComponent },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
