import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-landing',
  templateUrl: './loading-landing.component.html',
  styleUrl: './loading-landing.component.scss'
})
export class LoadingLandingComponent {

}
